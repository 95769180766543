<template>
  <div class="hello">
    <h1 class="mx-auto">
        tipranks details
    </h1>
  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
}
</script>
